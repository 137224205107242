import { useStableProps } from '@/hooks/useStable';
import StandaloneRelatedArticleSlider from 'base/components/RelatedArticleSlider';

export const HantStandaloneRelatedArticleSlider: typeof StandaloneRelatedArticleSlider = (props) => {
  const stableProps = useStableProps(
    {
      slider: {
        options: {
          $slider: {
            className: '!gap-2',
            $headline: {
              className: '!text-black',
            },
            $caption: {
              className: '!text-gray-700 my-2 !text-headline-xs',
            },
          },
        },
      },
    },
    props,
  );

  return <StandaloneRelatedArticleSlider {...stableProps} />;
};

export default HantStandaloneRelatedArticleSlider;
