/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "text-black",
  "headline_variant_default": "uppercase",
  "headline_size_default": "text-headline-sm",
  "slide_caption_variant_default": "hidden",
  "slide_caption_colors_default": "text-primary-300",
  "slide_caption_size_default": "text-body-sm",
  "slide_description_colors_default": "text-black",
  "slide_description_variant_default": "",
  "slide_description_size_default": "text-headline-sm",
  "slide_group_variant_default": "[counter-reset:most-read] flex-nowrap flex-row flex overflow-x-auto overflow-y-hidden scrollbar-thin snap-x",
  "slide_group_colors_default": "scrollbar-thumb-primary-700",
  "slide_group_size_default": "gap-3 pb-6",
  "slide_image_variant_default": "after:absolute after:bottom-0 after:content-[counter(most-read)] after:flex after:items-center after:justify-center after:right-1.5 after:rounded-full after:translate-y-1/2 relative",
  "slide_image_colors_primary": "after:bg-primary-700 after:text-white",
  "slide_image_size_default": "w-75 after:h-9 after:w-9 after:text-headline-md",
  "slide_variant_default": "[counter-increment:most-read] flex-col flex grow relative snap-center",
  "slide_colors_default": "",
  "slide_size_default": "gap-2.5 basis-0",
  "colors_default": "bg-white",
  "variant_default": "flex-col flex overflow-hidden",
  "size_default": "w-full gap-3 pt-6"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "MostRead");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;